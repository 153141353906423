.card-simple {
    background-color: #efefef;
    margin-top: 1rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 2px 2px 10px  lightgrey;
    /* border: 1px solid blue; */
}

.card-content {
    display: flex; 
    flex-direction: column;
     padding: 0 5em;
     justify-content: center;
      
}

.card-title {
    font-weight: bold;
    
}

.card-title a {
    color: #444;
}

.card-title a:hover {
    color: #444;
    text-decoration: underline;
}

.card-img {
    height: 10em;
    border-radius: 50%;
    box-shadow: 1px 1px 1px  darkgrey;
}

.card-desc {
    
    list-style: none;
}