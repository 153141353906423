/* .sidebar-icon {
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
} */

.sidebar-nav {
  flex: initial;
    /* background-image: url("../../img/OswegoRiver_2015.jpeg"); */
    /* background-size: cover; */
    background-color: #00000087;
    min-width: 430px;
    overflow-y: scroll;
}

  
.submenu-link {
    display: flex;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    height: 60px;
    text-decoration: none;
    font-size: 18px;
    font-weight: bolder;
    /* this transparent border allows the hover and active styles to be seen */
    border-right: 25px solid transparent;
}

.submenu-link:hover {
    background: #252831;
    cursor: pointer;
    border-right-color: #007fac;
  }

  .current {
    border-right-color: rgb(15, 206, 127);
    display: flex;
    align-items: center;
  }

  .submenu-link:last-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}


  .submenu-label{
    margin-left: 1em;
  }

  .submenu-link svg {
    width: 1.5em;
    height: 1.5em;
  }