.select-page {
    display: flex;
    justify-content: center;
    width: 100%;
    border: 1px solid blue;
    

}
.select-container {
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    padding: 5px 10px 0 10px;
    font-size: 20px;    
}



