.landing {
    display: flex;
    flex-direction: column;

    padding-top: 1px;
    background-image: url("../img/OswegoRiver_2015.jpeg");
    background-size: cover;
    height: 100vh;
    width: 100vw;

    color: white;
}

.landing-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    /* overflow: hidden; */

    color: white;
    font-size: 18px;

    background-color: #00626d87;
    margin-top: 100px;
}



.start button {
    display: block;
    

    
    background-color: rgb(0, 151, 89);
    padding: 20px;
    border: 1px solid white;
    margin: 20px auto;
        
    color: white !important;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
}

.start a:hover,
.start a:visited:hover, .start a:active,
.start a:visited:active {
    color: white;
    
    
}

.landing-subtext {
    max-width: 750px;
    text-align: center;

    font-size: 20px;
    font-weight: normal;
}