:table {
    --text-color: black;
    --text-shadow: 1px 1px 1px gray;;
}

/* table layout fixed sizes columns according to header width, changes in the .js with inline style */
/* border collapse prevents the double border look between table items */
.table-container {
    /* spacing */
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    /* border: 3px solid purple; */
}

.table-header {
    /* graphics and colors */
    /* background: url(leopardskin.jpg); */
    color: var(--text-color);
    text-shadow: var(--text-shadow);
}

.table-head {
    /* spacing */
    padding: 20px;    
    /* typography */
    letter-spacing: 2px;
    /* graphics and colors */
    /* background: linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)); */
    border-bottom: 3px solid gray;
}

.table-data {
    border-bottom: 1px solid gray;
    /* spacing */
    padding: 20px;
    /* typography */
    letter-spacing: 1px;
    text-align: center;
}

.tfooter-head {
    /* typography */
    text-align: right;

    /* graphics and colors */
    /* background: url(leopardskin.jpg); */
    color: var(--text-color);
    text-shadow: var(--text-shadow);
    /* background: linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)); */
    
}

.tfooter-data {
    /* spacing */
    padding: 20px;
    /* graphics and colors */
    /* background: linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)); */
    
}

/*  ### to make a specific column a specific width###  */ 
  /* thead th:nth-child(1) {
    width: 30%;
  }
*/
