.page-footer {
    border-top: 3px solid #444444;
    display: flex;
    justify-content: space-between;
    padding-left: 30px;

    color: #444444;
    font-size: 16px;

    background-color: #efefef;
    overflow: hidden;
}

.page-foot-item {
    flex: 1;
    padding: 10px 30px;
    align-self: center;
    text-align: center;
    justify-self: center;
}
.page-foot-item ul {
    padding-left: 10px;
    list-style: none;
}
.page-foot-item li{
    padding-bottom: 0.5rem;
}

.page-foot-item a:link, .page-foot-item a:visited{
    color: #444444;
    text-decoration: none;
    
}
.page-foot-item a:hover{
    color: #444444;
    text-decoration: underline;
    
}

.page-footer img {
    width: 200px;   
}


.copyright {
    font-size: small;
}

