:root{
    --bg: #efefef;
    --text-color: #444;
    --border: 1px solid #474a4d;
    --border-radius: 8px;
    --speed: 500ms;
    --blue: #007fac;
    --red: #c03;
    --green: rgb(0, 151, 89);
}

#root {
    width: 100%;
    
}

.default-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    
}

.default-content {
  height: 800px;
    display: flex;
    /* flex-direction: row; */
    
    justify-content: flex-start;
    /* align-items: center; */
    
}



.font-light {
    font-weight: 300;
}

.font-bold {
    font-weight: 700;
}



table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border: 3px solid grey;
    
  }
  
  thead th:nth-child(1) {
    width: 20%;
  }
  
  /* thead th:nth-child {
    width: 10%;
  } */
  
  /* thead th:nth-child(3) {
    width: 10%;
  }
  
  thead th:nth-child(4) {
    width: 10%;
  } */
  
  th, td {
    padding: rem;
  }

  tbody tr:nth-child(odd) {
    background-color: #cdcccd;
  }
  
  tbody tr:nth-child(even) {
    background-color: #b0b0b0;
  }


 /* th {
  letter-spacing: 0.8px;
} */

td {
  letter-spacing: 1px;
}

tbody td {
  text-align: center;
}

tfoot th {
  text-align: right;
}




