/* this way of defining the root allows us to use these as variables later */
/* :root {
  --bg: #242526;
  --bg-accent: #484a4d;
  --text-color: #dadce1;
  --nav-size: 60px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms;
} */

/* ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--text-color);
  text-decoration: none;
} */

.navbar {
height: 65px;  
background-color: #007fac;
padding: 0 1rem;
display: flex;
align-items: center;
 
}

.navbar-nav {
  display: flex;
  justify-content: flex-start;
  list-style: none;
  align-items: center;
  
}

/* each nav item is a box that is slightly smaller than the navbar itself */
.nav-item {
  padding: 10px;
  padding-right: 50px;  
}

.nav-item a {
  color: white;
  text-decoration: none;
  font-weight: 600;
  font-size: large;
}

/* .icon-button {
  --button-size: calc((var(--nav-size) * 0.5));
  width: var(--button-size);
  height: var(--button-size);
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: gray;
  transition: filter 300ms;
} */

/* .icon-button:hover {
  filter: brightness(1.2);
} */

/* .icon-button svg {
  fill: var(--text-color);
  width: 20px;
  height: 20px;
} */

/* .dropdown {
  position: absolute;
  top: 58px;
  width: 300px;
  transform: translateX(-45%);
  background-color: var(--bg);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 1rem;
  overflow: hidden;

  transition: height var(--speed) ease;
} */

/* .menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: 0.5rem;
} */

/* .menu-item:hover {
  background-color: #525357;
}  */

/* push right icon all the way to the right */
/* .icon-right {
  margin-left: auto;
} */

/* .menu {
  width: 100%;
} */

/* when in prop becomes true */
/* .menu-primary-enter{
  position: absolute;
  transform: translateX(-110%); */
  /* completely invisible off to the left */
/* }  */

/* after timeout of 500ms */
/* .menu-primary-enter-active{
  transform: translateX(0%);
  transition: all var(--speed) ease; */
  /* bring it to visible position */
/* } */

/* when in prop becomes false */
/* .menu-primary-exit{
  position: absolute;
} 
.menu-primary-exit-active{
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}


.menu-secondary-enter{
  position: absolute;
  transform: translateX(110%);
} 

.menu-secondary-enter-active{
  transform: translateX(0%);
  transition: all var(--speed) ease;
}

.menu-secondary-exit{
  position: absolute;
} 
.menu-secondary-exit-active{
  transform: translateX(110%);
  transition: all var(--speed) ease;
}  */

.njwa .nav-item .icon-button{
  font-size: x-large;
}