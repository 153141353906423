.landing-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    color: white;
    font-size: medium;

    background-color: rgba(8,87,108,.53);
    margin-top: 100px;
}

.foot-1 {
    flex: 1;
    padding-left: 50px;
    
}

.foot-1 p{
    margin-bottom: 0;
}

.foot-1 a{
    color: white;
    font-weight: 600;
    text-decoration: none;
}


.foot-2 {
    flex: 1;
    padding-left: 40px;
    display: flex;
    align-items: center;
}

.foot-2 img {
    max-width: 100px;
    margin-left: 50px;
}

.foot-2 h2 {
    display: inline;

}

footer hr {
    display: block;
    stroke: black;
    height: 5px;
    
}

.copyright {
    font-size: small;
}