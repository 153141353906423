.main-img {
    width: 80%;
    padding-top: 10px;
  }
  .main-content {
    flex: 1;
      display: flex;
      flex-direction: column;
      /* justify-content: flex-start; */
      align-items: center;
      padding: 1em;
      overflow-y: scroll;
  }
  .main-title {
    display: flex;
    justify-content: center;
    padding-bottom: 1em;
}

.main-text {
    max-width: 800px;
    padding-bottom: 1em;
    padding-top: 1em;
    
}  